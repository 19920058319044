// @ts-nocheck
/* eslint-disable */
import React from 'react';
import { jsPDF as JsPDF } from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode.react';
import encodeSvg from 'utils/encodeSvg';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { formatPriceToBsF, formatPriceToDollar } from 'utils/utils';
import { parseInt } from 'lodash';
import { getPaymentMethodsConfig } from 'stores/actions/config';
import { getPaymentMethods } from 'stores/selectors/config';
import { getRoutes } from 'stores/selectors/routes';
import { sortLocations } from 'utils/utils';

const usePrintOrderPdf = () => {
  const deliveryId = process.env.REACT_APP_DELIVERY_ID;
  const dispatch = useDispatch();
  const paymentMethods = useSelector(getPaymentMethods);
  const { data: routes } = useSelector(getRoutes);

  const getPaymentMethod = async () => {
    await dispatch(getPaymentMethodsConfig());
  };

  const printPdf = async (orders = [], roadmapNumber = null, route = null) => {
    const doc = new JsPDF();
    for (const ord in orders) {
      const order = orders[ord];

      const logo = encodeSvg(<LogoIcon />);

      const { products, name: nameOrder } = order.odooDataOrder;

      const productsSort = sortLocations(products);
      const { date, payment } = order;
      const createAt = date
        ? moment(date.toDate()).format('DD-MM-YYYY HH:mm')
        : '';
      const getPaymentMethod = (paymentName) => {
        // we need to change this trash elseifending
        let paymenttype = '';
        if (paymentName === 'stripe') {
          paymenttype = 'Tarjeta';
        } else if (paymentName === 'pagomovil') {
          order.payment.plazaDetails
            ? (paymenttype = 'Pagomovil Banco Plaza')
            : (paymenttype = 'Pagomovil Bancamiga');
        } else if (paymentName === 'binance') {
          paymenttype = 'Binance';
        } else if (paymentName === 'tdcve') {
          paymenttype = 'Bancamiga';
        } else if (paymentName === 'zelle') {
          paymenttype = 'Zelle';
        } else if (paymentName === 'bncPosTdc') {
          paymenttype = 'BNC';
        } else if (paymentName === 'bncPosNc') {
          paymenttype = 'BNC';
        } else if (paymentName === 'bncPos') {
          paymenttype = 'BNC';
        } else if (paymentName === 'botonbanesco') {
          paymenttype = 'Banesco';
        } else if (paymentName === 'credit') {
          paymenttype = 'Wallet';
        } else if (paymentName === 'preCredit') {
          paymenttype = 'Wallet';
        } else if (paymentName === 'cash') {
          paymenttype = 'Cash';
        } else if (paymentName === 'bolivarCash') {
          paymenttype = 'Bolivares Efectivo';
        } else {
          paymenttype =
            paymentMethods.find((method) => method.value === paymentName.trim())
              ?.name || {};
        }
        return paymenttype;
      };

      const paymentsSplited = payment?.type.split(', ') || [];
      const hasMethods = order?.payment?.hasOwnProperty('methods');
      const hasMixedPayment = hasMethods && order.payment.methods.length > 1;
      let payments = '';
      if (hasMixedPayment) {
        paymentsSplited.forEach((item) => {
          payments = payments + getPaymentMethod(item) + ', ';
        });
        payments = payments.slice(0, -2);
      } else {
        payments = getPaymentMethod(payment?.type);
      }

      const refUSD = order.ref;
      const qrCode = encodeSvg(<QRCode renderAs="svg" value={order.id} />);
      const yTitle = 70;
      let distanceAcc = yTitle * 0.6;

      doc.setFontSize(9);
      doc.addImage(qrCode, 'JPEG', 10, 10, 30, 30);
      doc.addImage(logo, 'JPEG', 90, 10, 35, 25);

      const amountFallback = productsSort.reduce(
        (acc, next) => acc + next.price_total,
        0
      );

      const clientName = order.customizedInvoice?.fullname || order?.clientName;
      const phoneAddress = order.addressDetails?.phone || '';
      const routeName =
        routes?.find((route) => route.id === order?.addressDetails?.routeId)
          ?.name || (order.shipping?.type === 'zoom' ? 'Zoom' : 'Pickup');

      let tableBody = [[`Orden: # ${nameOrder}`]];
      if (roadmapNumber) {
        tableBody.push([`Hoja de Ruta: # ${roadmapNumber}`] || Pickup);
      }
      if (order?.addressDetails?.routeId) {
        tableBody.push([`Ruta: ${routeName}`]);
      }
      tableBody.push(
        [`Fecha: ${createAt}`],
        [`Nombre: ${clientName}`],
        [`Cedula: ${order?.customizedInvoice?.dni || ''}`],
        [`Telefono Usuario: ${order?.customizedInvoice?.phone}`],
        [
          `Direccion: ${order?.addressDetails?.street || ''}, ${
            order?.addressDetails?.house || ''
          }`
        ],
        [`Indicaciones: ${order?.addressDetails?.instructions || ''}`],
        [`Telefono Direccion: ${phoneAddress}`],
        [`Metodo de pago: ${payments}`]
      );

      if (order?.company) {
        tableBody.push([`Compañia: ${order.company}`]);
      }

      if (order.customizedInvoice?.name)
        tableBody.push(['-- Factura personalizada --']);

      doc.autoTable({
        theme: 'plain',
        startY: distanceAcc,
        head: [['']],
        body: tableBody,
        styles: {
          cellPadding: 0.8
        }
      });

      const productsTable = [];
      productsSort.forEach(
        ({
          name,
          moves_location_id,
          price_total: priceTotal,
          quantity,
          x_studio_2x1
        }) => {
          const columns = [];
          columns.push(`${x_studio_2x1 === 'true' ? '[2x1] ' : ''}${name}`);
          columns.push(`${moves_location_id?.split('/')[2] || 'no asignado'}`);
          columns.push(`${quantity || 'no asignado'}`);
          columns.push(formatPriceToBsF(priceTotal));
          productsTable.push(columns);
        }
      );

      // @ts-ignore
      distanceAcc = doc.lastAutoTable.finalY + 5;
      doc.line(0, distanceAcc, 510, distanceAcc);
      // @ts-ignore
      distanceAcc = doc.lastAutoTable.finalY + 10;
      // @ts-ignore
      doc.autoTable({
        theme: 'plain',
        startY: distanceAcc,
        head: [['Producto', 'Ubicacion', 'Cantidad', 'Monto']],
        body: productsTable,
        columnStyles: {
          0: { cellWidth: 110 },
          1: { cellWidth: 20 },
          2: { cellWidth: 20 },
          3: { cellWidth: 30 }
        },
        headStyles: {
          Padding: {
            top: 10
          }
        },
        styles: {
          overflow: 'linebreak',
          // set fixed column width to avoid overflow
          columnWidth: '',
          cellPadding: 1.5,
          overflowColumns: 'linebreak'
        }
      });
      // @ts-ignore
      distanceAcc = doc.lastAutoTable.finalY + 5;

      doc.line(0, distanceAcc, 510, distanceAcc);

      if (parseInt(doc.lastAutoTable.finalY) > 200) {
        doc.addPage();
        distanceAcc = 10;
        doc.addImage(logo, 'JPEG', 90, 10, 35, 25);
        distanceAcc = 50;
      } else {
        // @ts-ignore
        distanceAcc = doc.lastAutoTable.finalY + 15;
      }

      /** TOTALS DETAILS **/

      const amount = order.total >= 0 ? order.total : amountFallback;

      let subtotal = parseFloat(amount) - parseFloat(order.tax);
      if (order?.IGTF) subtotal = subtotal - parseFloat(order.IGTF);
      subtotal = formatPriceToBsF(subtotal);

      const hasDiscount = order?.discount?.code;

      let totalsDetailsTable = [];
      let paymentValue = '';
      let paymentLabel = '';

      totalsDetailsTable.push(
        [['SUBTOTAL'], [`${subtotal}`]],
        [['IVA'], [`${formatPriceToBsF(order.tax)}`]]
      );

      paymentsSplited.forEach((item) => {
        const paymentType = item.trim();
        const paymentMethod = payment.methods.find(
          (record) => record.type.trim() === paymentType
        );
        paymentValue = paymentMethod?.amount || paymentMethod?.details?.amount;
        paymentValue = String(paymentValue || 0);
        paymentValue =
          paymentType === 'cash' ||
          paymentType === 'stripe' ||
          paymentType === 'binance'
            ? formatPriceToDollar(paymentValue)
            : formatPriceToBsF(paymentValue);
        paymentLabel = getPaymentMethod(paymentType).toUpperCase();
        if (totalsDetailsTable) {
          totalsDetailsTable.push([[`${paymentLabel}`], [`${paymentValue}`]]);
        }
      });

      if (order?.IGTF) {
        totalsDetailsTable.push([
          ['IGTF'],
          [`${formatPriceToBsF(order.IGTF)}`]
        ]);
      }

      totalsDetailsTable.push(
        [['TOTAL'], [`${formatPriceToBsF(amount)}`]],
        [['TOTAL USD'], [`${formatPriceToDollar(refUSD)}`]]
      );

      if (hasDiscount) {
        totalsDetailsTable.push(
          [['CODIGO'], [`${order?.discount?.code}`]],
          [['DESCUENTO'], [`${formatPriceToBsF(order?.discount?.totalBs)}`]]
        );
      }

      doc.autoTable({
        theme: 'plain',
        startY: distanceAcc,
        head: [[' ', ' ']],
        margin: {
          left: 115
        },
        body: totalsDetailsTable,
        styles: {
          cellPadding: 0.2,
          cellMargin: 0.8
        }
      });

      doc.addPage();
    }

    // remove last empty page
    const pageCount = doc.getNumberOfPages();
    doc.deletePage(pageCount);

    let fileName = '';
    if (roadmapNumber) {
      fileName = `${roadmapNumber}-${moment().format('DD/MM/YYYY')}`;
    } else {
      fileName = `PickUp - ${moment().format('DD/MM/YYYY')}`;
    }

    doc.save(`${fileName}.pdf`);
  };

  return {
    printPdf,
    getPaymentMethod,
    paymentMethods
  };
};

export default usePrintOrderPdf;
