import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Space,
  Divider,
  Badge,
  Popconfirm,
  Table,
  Tooltip,
  Button,
  Drawer,
  Row,
  Col,
  Tabs
} from 'antd';
import {
  EditOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  QrcodeOutlined,
  PrinterOutlined,
  RetweetOutlined,
  RestOutlined
} from '@ant-design/icons';

import { showNotification } from 'stores/actions/notification';
import { openModal, closeModal } from 'stores/actions/modal';

import useColumnSearch from 'hooks/useColumnSearch';
import { ROADMAPS } from 'constants/routes';
import { loadingWithSpinner, loaded } from 'stores/actions/loader';
import PrintValidation from 'pages/Orders/PickUp/PrintValidation';
import Can from 'components/Can';
import { ROADMAPS as ROADMAPS_MODULE } from 'components/Can/modules';

import { DiGitMerge } from 'react-icons/di';
import useMergeRoadmaps from 'hooks/useMergeRoadmaps';
import { mergeRoadmaps as mergeRoadmapsAction } from 'stores/actions/roadmaps';
import MergeRoadmapsModal from './MergeRoadmapsModal';
import useGetRoadmapsHook from 'hooks/componentHooks/useGetRoadmapsHook';
import useGetRidersHook from 'hooks/componentHooks/useGetRidersHook';
import FinishedRoadmaps from './FinishedRoadmaps/FinishedRoadmaps';
import RoadmapTranferOrders from './RoadmapTransferOrders/RoadmapTransferOrders';
import Utils from './utils';
import usePrintOrderPdf from 'hooks/usePrintOrderPdf';
import OrdersWithoutRoadmap from './OrdersWithoutRoadmap/OrdersWithoutRoadmap';

const Roadmaps = () => {
  const columnSearch = useColumnSearch();
  const {
    roadmapsData: roadmaps,
    roadmapsLoading,
    getRoadmaps
  } = useGetRoadmapsHook();

  const { ridersData, getRiders } = useGetRidersHook();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const {
    mergeRoadmaps,
    roadmapMerged,
    roadmapsCancelled,
    resetRoadmapsMerged,
    roadmapsSelected
  } = useMergeRoadmaps();
  const getTimeDifference = (createdAt, timeDiff) => {
    const now = new Date();
    const roadmapDate = new Date(createdAt);
    const diffInMs = now - roadmapDate;
    const diffInMinutes = diffInMs / (1000 * 60);
    return diffInMinutes > timeDiff;
  };
  const {
    showQRCode,
    onCloseRoadmap,
    cancelWaitingOrder,
    openAssignModal,
    printInvoice,
    assignManuallyNewRider
  } = Utils();

  const { getPaymentMethod, paymentMethods } = usePrintOrderPdf();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedRider, setSelectedRider] = useState('');

  const [selectedMergeableRoadmap, setSelectedMergeableRoadmap] =
    React.useState([]);

  const [mergeableActiveRoadmaps, setMergeableActiveRoadmaps] =
    React.useState();

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedMergeableRoadmap(_);
      mergeRoadmaps(selectedRows);
    },
    columnTitle: ' ',
    getCheckboxProps: (record) => ({ disabled: record?.orders?.length === 0 }),
    selectedRowKeys: selectedMergeableRoadmap
  };

  const getMergeableActiveRoadmaps = () => {
    const modalContent = {
      component: MergeRoadmapsModal,
      props: {
        mergeableActiveRoadmaps,
        selectedMergeableRoadmap: roadmapMerged,
        setSelectedMergeableRoadmap,
        cancel: () => dispatch(closeModal())
      }
    };

    dispatch(
      openModal({
        title: 'Seleccione el roadmap activo que desee mezclar.',
        content: modalContent,
        buttons: []
      })
    );
  };

  React.useEffect(() => {
    setMergeableActiveRoadmaps(
      roadmaps.filter(
        (roadmap) =>
          roadmap.isOnDelivery === false &&
          roadmap.status !== 'completed' &&
          roadmap.status !== 'waiting'
      )
    );
  }, [roadmaps]);

  React.useEffect(() => {
    if (!paymentMethods || paymentMethods.length <= 1) getPaymentMethod();
    getRiders();
    const unsusbscribe = getRoadmaps();
    return () => {
      if (typeof unsusbscribe === 'function' && unsusbscribe) unsusbscribe();
    };
    // eslint-disable-next-line
  }, []);

  const getColumns = () => [
    {
      title: t('pages.roadmaps.table.roadmap'),
      dataIndex: 'roadmapNumber',
      key: 'roadmapNumber',
      ...columnSearch('roadmapNumber'),
      render: (text, record) => (
        <Link
          to={{
            pathname: `${ROADMAPS}/${record.roadmapNumber}`
          }}
        >
          {text}
        </Link>
      )
    },
    {
      title: t('pages.roadmaps.table.rider'),
      key: 'rider',
      ...columnSearch(['rider', 'name']),
      render: (roadmap) => roadmap?.rider?.name || 'Rider no asignado'
    },
    {
      title: t('pages.riders.table.proco'),
      key: 'proco',
      ...columnSearch('proco'),
      render: (roadmap) => roadmap?.rider?.proco || '-'
    },
    {
      title: t('pages.roadmaps.table.route'),
      key: 'route',
      ...columnSearch(['route', 'name']),
      render: (roadmap) => roadmap?.routeName || ''
    },
    {
      title: t('pages.roadmaps.table.orders'),
      key: 'orders',
      render: (roadmap) => roadmap?.ordersIds?.length
    },
    {
      title: t('pages.roadmaps.table.status'),
      key: 'status',
      ...columnSearch('status'),
      render: (roadmap) => {
        const delayed = getTimeDifference(roadmap.createdAt, 60);
        return (
          <Badge
            status={delayed ? 'processing' : 'success'}
            color={delayed ? 'volcano' : 'green'}
          />
        );
      }
    },
    {
      title: t('pages.roadmaps.table.actions'),
      key: 'action',
      render: (roadmap) => (
        <>
          {roadmap.status !== 'completed' ? (
            <Space size="middle" key={roadmap.id}>
              <Can I="update" a={ROADMAPS_MODULE}>
                <Tooltip placement="top" title="Asignar Rider / Crossdocking">
                  <EditOutlined
                    style={{ fontSize: '1.2rem' }}
                    onClick={() => assignManuallyNewRider(roadmap)}
                  />
                </Tooltip>
              </Can>
              <Can I="update" a={ROADMAPS_MODULE}>
                <Popconfirm
                  title="Seguro que quiere completar este Roadmap?"
                  onConfirm={() => onCloseRoadmap(roadmap)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip placement="top" title="Completar Roadmap">
                    <CloseSquareOutlined style={{ fontSize: '1.2rem' }} />
                  </Tooltip>
                </Popconfirm>
              </Can>
              {roadmap?.ordersIds?.length >= 1 && (
                <Can I="cancel" a={ROADMAPS_MODULE}>
                  <Tooltip placement="top" title="Cancelar orden">
                    <RestOutlined
                      onClick={() => cancelWaitingOrder(roadmap)}
                      style={{ fontSize: '1.2rem' }}
                    />
                  </Tooltip>
                </Can>
              )}
              <Can I="update" a={ROADMAPS_MODULE}>
                <Tooltip placement="top" title="Ver QR de Ordenes">
                  <QrcodeOutlined
                    style={{ fontSize: '1.2rem' }}
                    onClick={() => showQRCode(roadmap)}
                  />
                </Tooltip>
              </Can>
            </Space>
          ) : (
            roadmap.status === 'completed' && (
              <Can I="update" a={ROADMAPS_MODULE}>
                <Tooltip placement="top" title="Imprimir Order de Entrega">
                  <PrinterOutlined
                    style={{ fontSize: '1.2rem' }}
                    onClick={() => {
                      printInvoice({
                        roadmapNumber: roadmap.id,
                        route: roadmap.routeName,
                        ordersIds: roadmap.ordersIds
                      });
                    }}
                  />
                </Tooltip>
              </Can>
            )
          )}
        </>
      )
    }
  ];

  const waitingRoadmaps = () => [
    {
      title: t('pages.roadmaps.table.roadmap'),
      dataIndex: 'roadmapNumber',
      key: 'roadmapNumber'
    },
    {
      title: t('pages.roadmaps.table.route'),
      key: 'route',
      render: (roadmap) => roadmap?.routeName
    },
    {
      title: t('pages.roadmaps.table.orders'),
      key: 'orders',
      render: (roadmap) => roadmap?.ordersIds?.length
    },
    {
      title: t('pages.roadmaps.table.status'),
      key: 'status',
      ...columnSearch('status'),
      render: (roadmap) => {
        const delayed = getTimeDifference(roadmap.createdAt, 20);
        return (
          <Badge
            status={delayed ? 'processing' : 'success'}
            color={delayed ? 'volcano' : 'green'}
          />
        );
      }
    },
    {
      title: t('pages.roadmaps.table.actions'),
      key: 'action',
      render: (roadmap) => {
        const assignRiderButton = (
          <Tooltip placement="top" title="Asignacion de Rider">
            <CheckSquareOutlined
              style={{ fontSize: '1.2rem' }}
              onClick={() => openAssignModal(roadmap)}
              data-testid="assign-rider-button"
            />
          </Tooltip>
        );
        return (
          <Space size="middle" key={roadmap.id}>
            {process.env.NODE_ENV === 'test' ? (
              assignRiderButton
            ) : (
              <Can I="update" a={ROADMAPS_MODULE}>
                {assignRiderButton}
              </Can>
            )}
            <Tooltip placement="top" title="Ver QR de Ordenes">
              <QrcodeOutlined
                style={{ fontSize: '1.2rem' }}
                onClick={() => showQRCode(roadmap)}
              />
            </Tooltip>
            <Can I="update" a={ROADMAPS_MODULE}>
              <Tooltip placement="top" title="Imprimir Order de Entrega">
                <PrinterOutlined
                  style={{ fontSize: '1.2rem' }}
                  onClick={() => {
                    printInvoice({
                      roadmapNumber: roadmap.id,
                      route: roadmap.routeName,
                      ordersIds: roadmap.ordersIds
                    });
                  }}
                />
              </Tooltip>
            </Can>
            <Can I="cancel" a={ROADMAPS_MODULE}>
              <Tooltip placement="top" title="Cancelar orden">
                <RestOutlined
                  onClick={() => cancelWaitingOrder(roadmap)}
                  style={{ fontSize: '1.2rem' }}
                />
              </Tooltip>
            </Can>
          </Space>
        );
      }
    }
  ];

  const handlerDrawer = () => {
    setSelectedRider('');
    setIsOpenDrawer(!isOpenDrawer);
  };

  const roadmapsDataInProgress =
    roadmaps.filter((roadmap) => roadmap.status === 'inprogress') || [];
  const roadmapsDataAwaiting = roadmaps.filter(
    (roadmap) => roadmap.status === 'waiting'
  );

  const onMergeRoadmaps = async () => {
    dispatch(loadingWithSpinner());
    /// merge roadmaps
    try {
      await dispatch(
        // @ts-ignore
        mergeRoadmapsAction({
          roadmapMerged,
          roadmapsToUpdate: roadmapsCancelled
        })
      );

      resetRoadmapsMerged();
      dispatch(loaded());
      dispatch(
        showNotification({
          type: 'success',
          message: 'Proceso realizado satisfactoriamente'
        })
      );
    } catch (error) {
      dispatch(loaded());
      dispatch(
        showNotification({
          type: 'error',
          message: 'Error',
          content: error.message
        })
      );
    }
  };

  return (
    <>
      <Tabs data-testid="roadmaps-page">
        <TabPane tab="Roadmaps" key="1">
          <Divider orientation="left">
            {t('pages.roadmaps.table.waiting')}
          </Divider>
          <Space style={{ width: '100%' }} direction="vertical">
            <Space>
              <Can I="transfer" a="roadmaps">
                <Button
                  onClick={() => handlerDrawer()}
                  icon={<RetweetOutlined />}
                  shape="round"
                >
                  Transferir Ordenes entre Roadmaps
                </Button>
              </Can>
              <Can I="merge" a="roadmaps">
                <Popconfirm
                  disabled={roadmapsSelected.length <= 1}
                  title="¿Está seguro de querer reasignar las ordenes?"
                  onConfirm={() => onMergeRoadmaps()}
                  okText="Confirmar"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    icon={<DiGitMerge />}
                    shape="round"
                    ghost
                    disabled={roadmapsSelected.length <= 1}
                  >
                    Mezclar Hojas de Ruta
                  </Button>
                </Popconfirm>
              </Can>
              <Can I="merge" a="roadmaps">
                <Button
                  type="primary"
                  icon={<DiGitMerge />}
                  shape="round"
                  disabled={selectedMergeableRoadmap.length !== 1}
                  onClick={getMergeableActiveRoadmaps}
                >
                  Mezclar con un Roadmap Activo
                </Button>
              </Can>
            </Space>
            <Table
              columns={waitingRoadmaps()}
              loading={roadmapsLoading}
              dataSource={roadmapsDataAwaiting}
              rowKey="id"
              rowSelection={rowSelection}
            />
          </Space>
          <Divider orientation="left">
            {t('pages.roadmaps.table.actives')}
          </Divider>
          <Table
            columns={getColumns()}
            loading={roadmapsLoading}
            dataSource={roadmapsDataInProgress}
            rowKey="id"
          />
          <Drawer
            destroyOnClose
            forceRender
            width={600}
            visible={isOpenDrawer}
            onClose={() => handlerDrawer()}
          >
            <Row style={{ paddingTop: '30px' }}>
              <Col span={24}>
                <RoadmapTranferOrders
                  riders={ridersData}
                  selectedRider={selectedRider}
                  setSelectedRider={setSelectedRider}
                  handlerDrawer={handlerDrawer}
                />
              </Col>
            </Row>
          </Drawer>
        </TabPane>
        <TabPane tab="Roadmaps Finalizados" key="2">
          <FinishedRoadmaps />
        </TabPane>
        <TabPane tab="Orders Pickup" key="3">
          <PrintValidation isZoom={false} />
        </TabPane>
        <TabPane tab="Orders Zoom" key="4">
          <PrintValidation isZoom />
        </TabPane>
        <TabPane tab="Ordenes sin Roadmap" key="5">
          <OrdersWithoutRoadmap roadmaps={roadmaps} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Roadmaps;
